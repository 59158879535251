<template>
    <div class="dashboard-container">
        <el-row :gutter="20">
            <el-card>
                <el-row type="flex">
                    <el-col :span="6" v-if="$admin">
                        <span class="text-primary text-pad-right">公司：</span>
                        <GlobalCompanySelect
                            v-model="ajaxParam.companyId"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入任务名称"
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>

                    <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >关键字查询：</span
                        >
                        <el-input
                            maxlength="20"
                            v-model="ajaxParam.searchName"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入车牌号、车辆自编号或保单号"
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="margin-top: 20px">
                <el-row>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="between">
                                <el-col>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="
                                            op.id = '0';
                                            op.code = 'add';
                                            op.show = true;
                                        "
                                        >添加</el-button
                                    >
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="
                                            op.id = '0';
                                            op.code = 'export';
                                            op.show = true;
                                        "
                                        >批量导入</el-button
                                    >
                                    <el-button
                                        type="danger"
                                        size="mini"
                                        @click="del([])"
                                        >批量删除</el-button
                                    >
                                </el-col>
                                <el-col :span="1">
                                    <!-- <el-button
                                        size="mini"
                                        type="primary"
                                        @click="queryList"
                                        >查询</el-button
                                    > -->
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="onRefresh()"
                                        >刷新</el-button
                                    >
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>

                    <vxe-table
                        ref="vxeTable"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyleFn"
                        align="center"
                        class="vxe-table-element"
                        height="544"
                        :data="records"
                        show-overflow="title"
                        :sort-config="{ sortMethod: customSortMethod }"
                    >
                        <vxe-table-column
                            type="checkbox"
                            width="60"
                            fixed="left"
                        />

                        <!-- <vxe-table-column type="seq" title="序号" width="120">
                            <template v-slot="{ rowIndex }">
                                {{ rowIndex + 1 }}
                            </template>
                        </vxe-table-column> -->

                        <vxe-table-column title="车牌号">
                            <template v-slot="{ row }">
                                {{ row.carNum }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="车辆自编号">
                            <template v-slot="{ row }">
                                {{ row.carNo }}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column title="所属公司">
                            <template v-slot="{ row }">
                                {{ row.companyName }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保单名称">
                            <template v-slot="{ row }">
                                {{ row.policyTypeStr }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保险公司">
                            <template v-slot="{ row }">
                                {{ row.policyCompany }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保单号">
                            <template v-slot="{ row }">
                                {{ row.policyNum }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保障金额(元)">
                            <template v-slot="{ row }">
                                &yen; {{ row.guaranteeAmount }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保费金额(元)">
                            <template v-slot="{ row }">
                                &yen; {{ row.premiumAmount }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="保险期间">
                            <template v-slot="{ row }">
                                {{ row.takeEffectTime }}~{{ row.invalidTime }}
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            title="距离失效还剩"
                            sortable
                            field="daysRemain"
                        >
                            <template v-slot="{ row }">
                                <div
                                    :style="
                                        row.daysRemain < 60
                                            ? `color: #DB1A1A`
                                            : ``
                                    "
                                >
                                    {{ row.daysRemain || "--" }}天
                                </div>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="状态">
                            <template v-slot="{ row }">
                                <div
                                    :style="
                                        row.status === '保障中'
                                            ? `color: rgb(12, 184, 128)`
                                            : ``
                                    "
                                >
                                    {{ row.status }}
                                </div>
                            </template>
                        </vxe-table-column>

                        <vxe-table-column field="" title="操作" width="120">
                            <template v-slot="{ row }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i
                                            class="el-icon-arrow-down el-icon--right"
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            icon="el-icon-edit"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'edit';
                                            "
                                            >修改
                                        </el-dropdown-item>
                                        <!-- <el-dropdown-item
                                            icon="el-icon-view"
                                            @click.native="
                                                op.show = true;
                                                op.id = row.id;
                                                op.code = 'look';
                                            "
                                            >详情
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            icon="el-icon-delete"
                                            @click.native="del(row.id)"
                                            >下架
                                        </el-dropdown-item> -->
                                        <el-dropdown-item
                                            icon="el-icon-delete"
                                            @click.native="del(row.id)"
                                            >删除
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>

                    <div style="height: 20px"></div>
                    <el-pagination
                        background
                        :current-page.sync="ajaxParam.currentPage"
                        :page-sizes="[5, 10, 15, 20, 50, 100]"
                        :page-size.sync="ajaxParam.pageSize"
                        layout="total, ->, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="queryList"
                        @current-change="queryList"
                    />
                </el-row>
            </el-card>
        </el-row>

        <OperatData
            :op="op"
            :policyType="ajaxParam.policyType"
            :title="op.id == '0' ? '添加' : '修改'"
            :visible.sync="op.show"
            v-if="op.code == 'add' || op.code == 'edit'"
            @close="onClose"
            @query="queryList"
        ></OperatData>

        <LookData
            :op="op"
            title="查看"
            :visible.sync="op.show"
            v-if="op.code == 'look'"
            @close="onClose"
        ></LookData>

        <GlobalBatchExport
            title="批量导入"
            :visible.sync="op.show"
            v-if="op.code == 'export'"
            @query="queryList"
            @close="onClose"
            downloadPath="/car/server/escort/web/carPolicy/template/download"
            uploadPath="/car/server/escort/web/carPolicy/export"
        >
        </GlobalBatchExport>
    </div>
</template>

<script>
import OperatData from "../components/OperatData.vue";
import LookData from "../components/LookData.vue";
export default {
    components: { LookData, OperatData },
    name: "knowledgeList",
    data() {
        return {
            ajaxParam: {
                currentPage: 1,
                pageSize: 20,
                policyType: "1", // 机动车商业保险单-0    机动车交通事故责任强制保单-1
                companyId: this.$admin ? "" : this.$companyId,
                searchName: "", //查询内容（车牌号、车辆自编号、保单号）
                direction: "ASC", //排序方式，默认降序 DESC, ASC
                property: "daysRemain", // 排序字段，默认创建时间
            },

            records: [],
            total: 0,

            op: {
                code: "init", // "look" "edit" "add" 操作code  "export"导出
                show: false,
                id: "0", //id 为0则表示添加
                // row: null, //要操作的列表选中的一项  当前要操作的行
            },
        };
    },
    created() {
        this.queryList();
    },
    methods: {
        onRefresh() {
            // 刷新
            Object.keys((key) => {
                this.ajaxParam[key] = "";
            });
            this.ajaxParam.currentPage = 1;
            this.ajaxParam.pageSize = 20;
            this.queryList();
        },
        onClose() {
            this.op.id = "0";
            this.op.code = "init";
            this.op.show = false;
        },
        customSortMethod({ sortList }) {
            const { order } = sortList[0];
            if (this.ajaxParam.direction == order.toUpperCase()) {
                return null;
            }
            this.ajaxParam.direction = order == "asc" ? "ASC" : "DESC";
            this.queryList();
        },
        async queryList() {
            let { code, data } = await this.$api.get(
                "/car/server/escort/web/carPolicy",
                {
                    params: this.ajaxParam,
                }
            );
            if (code === 200) {
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        },

        del(val) {
            // 删除操作
            let ids = [];
            if (Array.isArray(val)) {
                //批量选择
                ids = this.$refs.vxeTable
                    .getCheckboxRecords()
                    .map((item) => item.id);
                if (ids.length === 0) {
                    this.$message({
                        message: "请选择要删除的数据",
                        type: "warning",
                        center: true,
                    });
                    return null;
                }
            }

            this.$confirm("确认删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(async () => {
                    let { code } = await this.$api.delete(
                        `/car/server/escort/web/carPolicy/${
                            Array.isArray(val) ? ids + "" : val
                        }`
                    );
                    if (code === 200) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                            center: true,
                        });
                        this.queryList();
                    }
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.dashboard-container {
    box-sizing: border-box;
    padding: 0 10px;
}
</style>
