<template>
    <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="机动车商业保险" name="first">
            <commercialInsurance></commercialInsurance>
        </el-tab-pane>
        <el-tab-pane label="机动车交通事故责任强制保单" name="second">
            <compulsoryTrafficInsurance></compulsoryTrafficInsurance>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import commercialInsurance from "./commercialInsurance/HomeView";
import compulsoryTrafficInsurance from "./compulsoryTrafficInsurance/HomeView";
export default {
    components: { commercialInsurance, compulsoryTrafficInsurance },
    data() {
        return {
            activeName: "first",
        };
    },
};
</script>

<style lang="scss" scoped></style>
