<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            size="mini"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                placeholder="请选择公司"
                prop="key1"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'车牌号'}:`"
                placeholder="请输入车牌号"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保险公司名称'}:`"
                placeholder="请输入保险公司名称"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保单名称'}:`"
                placeholder="请输入保单名称"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保障金额'}:`"
                placeholder="请输入保障金额"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保单号'}:`"
                placeholder="请输入保单号"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保费金额'}:`"
                placeholder="请输入保费金额"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
            <el-form-item
                :label="`${'保险期间'}:`"
                placeholder="请选择保险期间"
                prop="key2"
            >
                {{ ajaxParam.key1 }}
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    title: "查看数据",
    name: "LookData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },
    data() {
        return {
            ajaxParam: {
                key1: "",
                key2: "",
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carPolicy/${this.op.id}`
            );
            if (code == 200) {
                // this.ajaxParam = data;
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
}
</style>
