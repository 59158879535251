<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                placeholder="请选择公司"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect
                    v-model="ajaxParam.companyId"
                    @change="
                        () => {
                            ajaxParam.carId = null;
                        }
                    "
                ></GlobalCompanySelect>
            </el-form-item>

            <el-form-item
                :label="`${'车牌号'}:`"
                placeholder="请输入车牌号"
                prop="carId"
            >
                <GlobalCarSelect
                    :companyId="ajaxParam.companyId"
                    v-model="ajaxParam.carId"
                ></GlobalCarSelect>
            </el-form-item>

            <el-form-item :label="`${'保险公司名称'}:`" prop="policyCompany">
                <el-input
                    v-model="ajaxParam.policyCompany"
                    placeholder="请输入保险公司名称"
                ></el-input>
            </el-form-item>

            <!-- <el-form-item
                :label="`${'保单名称'}:`"
                placeholder="请输入保单名称"
                prop="policyType"
            >
                <el-input v-model="ajaxParam.policyType"></el-input>
            </el-form-item> -->

            <el-form-item :label="`${'保障金额'}:`" prop="guaranteeAmount">
                <el-input
                    v-model="ajaxParam.guaranteeAmount"
                    placeholder="请输入保障金额"
                >
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>

            <el-form-item :label="`${'保单号'}:`" prop="policyNum">
                <el-input
                    v-model="ajaxParam.policyNum"
                    placeholder="请输入保单号"
                ></el-input>
            </el-form-item>

            <el-form-item :label="`${'保费金额'}:`" prop="premiumAmount">
                <el-input
                    v-model="ajaxParam.premiumAmount"
                    placeholder="请输入保费金额"
                >
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>

            <el-form-item :label="`${'保险期间'}:`" prop="daterange">
                <GlobalElDatePicker
                    start-placeholder="请选择开始时间"
                    end-placeholder="请选择结束时间"
                    type="daterange"
                    v-model="ajaxParam.daterange"
                ></GlobalElDatePicker>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
        policyType: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            ajaxParam: {
                carId: "", //车辆id
                companyId: this.$admin ? "" : this.$companyId, //所属公司
                guaranteeAmount: "", //保障金额
                policyCompany: "", //保险公司
                policyNum: "", //保单号
                policyType: "", //保单类型
                premiumAmount: "", //保费金额
                takeEffectTime: "", //保险生效时间
                invalidTime: "", //保险失效时间
                daterange: [],
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.ajaxParam.policyType = this.policyType;
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carPolicy/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
                this.ajaxParam.guaranteeAmount = data.guaranteeAmount + "";
                this.ajaxParam.premiumAmount = data.premiumAmount + "";
                this.ajaxParam.daterange = [
                    this.ajaxParam.takeEffectTime,
                    this.ajaxParam.invalidTime,
                ];
            }
        },
        async submitData() {
            const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            delete ajaxParam.daterange;
            ajaxParam.takeEffectTime = this.ajaxParam.daterange[0];
            ajaxParam.invalidTime = this.ajaxParam.daterange[1];
            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post(
                          "/car/server/escort/web/carPolicy",
                          null,
                          {
                              params: ajaxParam,
                          }
                      )
                    : await this.$api.put(
                          `/car/server/escort/web/carPolicy/${this.op.id}`,
                          null,
                          {
                              params: ajaxParam,
                          }
                      );
            if (code == 200) {
                this.$emit("query");
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
</style>
