var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-card',[_c('el-row',{attrs:{"type":"flex"}},[(_vm.$admin)?_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司：")]),_c('GlobalCompanySelect',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"请输入任务名称"},on:{"input":_vm.queryList},model:{value:(_vm.ajaxParam.companyId),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "companyId", $$v)},expression:"ajaxParam.companyId"}})],1):_vm._e(),_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("关键字查询：")]),_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"maxlength":"20","size":"mini","suffix-icon":"el-icon-search","placeholder":"请输入车牌号、车辆自编号或保单号"},on:{"input":_vm.queryList},model:{value:(_vm.ajaxParam.searchName),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "searchName", $$v)},expression:"ajaxParam.searchName"}})],1)],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"between"}},[_c('el-col',[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.op.id = '0';
                                        _vm.op.code = 'add';
                                        _vm.op.show = true;}}},[_vm._v("添加")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.op.id = '0';
                                        _vm.op.code = 'export';
                                        _vm.op.show = true;}}},[_vm._v("批量导入")]),_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.del([])}}},[_vm._v("批量删除")])],1),_c('el-col',{attrs:{"span":1}},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onRefresh()}}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"vxeTable",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"544","data":_vm.records,"show-overflow":"title","sort-config":{ sortMethod: _vm.customSortMethod }}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"title":"车牌号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.carNum)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"车辆自编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.carNo)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"所属公司"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.companyName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保单名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.policyTypeStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保险公司"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.policyCompany)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保单号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.policyNum)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保障金额(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" ¥ "+_vm._s(row.guaranteeAmount)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保费金额(元)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" ¥ "+_vm._s(row.premiumAmount)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"保险期间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_vm._v(" "+_vm._s(row.takeEffectTime)+"~"+_vm._s(row.invalidTime)+" ")]}}])}),_c('vxe-table-column',{attrs:{"title":"距离失效还剩","sortable":"","field":"daysRemain"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_c('div',{style:(row.daysRemain < 60
                                        ? "color: #DB1A1A"
                                        : "")},[_vm._v(" "+_vm._s(row.daysRemain || "--")+"天 ")])]}}])}),_c('vxe-table-column',{attrs:{"title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_c('div',{style:(row.status === '保障中'
                                        ? "color: rgb(12, 184, 128)"
                                        : "")},[_vm._v(" "+_vm._s(row.status)+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"","title":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var row = ref.row;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){_vm.op.show = true;
                                            _vm.op.id = row.id;
                                            _vm.op.code = 'edit';}}},[_vm._v("修改 ")]),_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除 ")])],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.ajaxParam.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.ajaxParam.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.ajaxParam, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.ajaxParam, "pageSize", $event)},"size-change":_vm.queryList,"current-change":_vm.queryList}})],1)],1)],1),(_vm.op.code == 'add' || _vm.op.code == 'edit')?_c('OperatData',{attrs:{"op":_vm.op,"policyType":_vm.ajaxParam.policyType,"title":_vm.op.id == '0' ? '添加' : '修改',"visible":_vm.op.show},on:{"update:visible":function($event){return _vm.$set(_vm.op, "show", $event)},"close":_vm.onClose,"query":_vm.queryList}}):_vm._e(),(_vm.op.code == 'look')?_c('LookData',{attrs:{"op":_vm.op,"title":"查看","visible":_vm.op.show},on:{"update:visible":function($event){return _vm.$set(_vm.op, "show", $event)},"close":_vm.onClose}}):_vm._e(),(_vm.op.code == 'export')?_c('GlobalBatchExport',{attrs:{"title":"批量导入","visible":_vm.op.show,"downloadPath":"/car/server/escort/web/carPolicy/template/download","uploadPath":"/car/server/escort/web/carPolicy/export"},on:{"update:visible":function($event){return _vm.$set(_vm.op, "show", $event)},"query":_vm.queryList,"close":_vm.onClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }