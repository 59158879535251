import { charMax, requiredInput, requiredSelect, priceFn } from "@/rules";
export const rules = {
    companyId: [requiredSelect()],
    carId: [requiredSelect()],
    policyCompany: [requiredInput(), charMax(20)],
    guaranteeAmount: [requiredInput(), charMax(10), priceFn()],
    policyNum: [
        requiredInput(),
        charMax(40),
        {
            trigger: "change",
            validator: (rule, value, callback) => {
                if (!/^[0-9a-zA-Z]*$/g.test(value + "")) {
                    callback(new Error("保险单号只能输入字母和数字"));
                } else {
                    callback();
                }
            },
        },
    ],
    premiumAmount: [requiredInput(), charMax(10), priceFn()],
    daterange: [requiredSelect()],
    key2: [
        requiredSelect(),
        {
            trigger: "change",

            validator: (rule, value, callback) => {
                if (!/^\d*$/.test(value + "")) {
                    callback(new Error("排序只能输入0或正整数"));
                } else if ((value + "").length > 5) {
                    callback(new Error("排序字符长度不超过5个字符"));
                } else {
                    callback();
                }
            },
        },
    ],
};
